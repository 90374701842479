import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Iapiresponse } from '../interfaces/iapiresponse';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 
       'Accept': 'application/json'})
  };
  private url: string;
  private endpoint: string;

  constructor(private http: HttpClient) {
    this.url= environment.APIREST;
    this.endpoint="util";
   }

   getMccs(){
   
    return this.http.get<Iapiresponse<Array<any>>>(`${this.url}/${this.endpoint}/${'mccs'}`)
    .pipe(
      map(e=>e.result)
    );
   }

   getDepartments(){
   
    return this.http.get<Iapiresponse<Array<any>>>(`${this.url}/${this.endpoint}/${'departments'}`)
    .pipe(
      map(e=>e.result)
    );
   }

   getProvinces(department: string){
    let params = new HttpParams();
    params = params.append('department', department);
    return this.http.get<Iapiresponse<Array<any>>>(`${this.url}/${this.endpoint}/${'provinces'}`, {params:params})
    .pipe(
      map(e=>e.result)
    );
   }

   getDistricts(department: string, province:string){
    let params = new HttpParams();
    params = params.append('department', department);
    params= params.append('province', province)
    return this.http.get<Iapiresponse<Array<any>>>(`${this.url}/${this.endpoint}/${'districts'}`, {params:params})
    .pipe(
      map(e=>e.result)
    );
   }


   getUtilityOptions(id: string, params=new HttpParams()){
    return this.http.get<Iapiresponse<Array<any>>>(`${this.url}/${'utilities'}/${id}`, {params: params})
    .pipe(
      map(e=>e.result)
    );
   }

  getBanks(){
    return environment.BANKS;
  
   }
}
